.footer {
    background-color: var(--color-dark);
    height: 30vmin;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 5vmin;
}

.footerText {
    color: var(--color-light);
    text-align: center;
    font-size: 1.25rem;
    font-weight: 400;
}

.footerLink {
    color: var(--color-light);
    /* height: 36px; */
    height: 2.25rem;
    text-align: center;
}

.footerLink .instaIcon {
    display: inline-block;
    /* width: 36px; */
    width: 2.25rem;
    /* height: 36px; */
    height: 2.25rem;
}

.footerLink .instaIcon svg {
    /* width: 36px; */
    width: 2.25rem;
    /* height: 36px; */
    height: 2.25rem;
}

.footerLink .instaName {
    vertical-align: top;
    display: inline-block;
    /* height: 36px; */
    height: 2.25rem;
    /* line-height: 36px; */
    line-height: 2.25rem;
    font-weight: 900;
    font-size: 1.25rem;
    margin-left: 10px;
}

.footerCopyright {
    margin-top: 1vmin;
    color:var(--color-gray);
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
}
