.container {
    position: relative;
    width: 100%;
    padding: 0;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    background-color: black;
}

.containerLinks {
    text-align: center;
    margin-bottom: 30px;
}

.containerLinksSmall {
    position: relative;
    width: 20px;
    height: 20px;
    padding: 0;
    border-radius: 50%;
    outline: none;
    border: 0;
    background-color: var(--color-dark);
    transition: background-color 1s ease;
    margin: 0 5px;
    cursor: pointer;
}

.containerLinksSmallActive {
    background-color: var(--color-for-details);
}

.sliderItem {
    box-sizing: border-box;
    min-width: 100%;
    height: auto;
    overflow: hidden;
    object-fit: cover;
    height: auto;
    transform: translateX(0);
    transition: transform 1s ease;
}

.sliderItem img {
    width: 100%;
    height: auto;
}

.sliderFooter {
     display: flex;
     margin-top: 10px;
     height: 70px;
     column-gap: 50px;
}

.sliderImgText {
    text-align: right;
    font-weight: 300;
    font-size: 1rem;
    font-style: italic;
}