@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url(../public/fonts/inter_v18/UcCm3FwrK3iLTcvnUwQT9g.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(../public/fonts/inter_v18/UcCo3FwrK3iLTcviYwY.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

body {
  background-color: var(--color-light);
}


/* Default: Large devices such as laptops, computers (greater than 1024px) */
html {
  font-size: 100%; /* 1rem = 16px */
}

/* Medium devices such as tablets (1024px or lesser) */
@media only screen and (max-width: 1024px) {
  html {
    font-size: 75%; /* 1rem = 8px */
  }
}

/* Small devices such as phones (768px or lesser) */
@media only screen and (max-width: 768px) {
  html {
    font-size: 50%; /* 1rem = 4px */
  }
}