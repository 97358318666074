.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --color-light: white; /*#e2e2e2*/
  --color-semi-light: #D9D9D9;
  --color-gray: #A1A1A1;
  --color-dark: black;
  --color-for-details: #6DA7E1;
  --color-hover-primary: #95BCE3;
  --color-text-red: #F20000;
  --color-text-blue: var(--color-for-details);
  --slogan-size: 11vmin;
  --h1-text-size: calc(var(--slogan-size) / (169 / 60));
  --h2-text-size: calc(var(--slogan-size) / (169 / 36) * 1.4);
  --h3-text-size: calc(var(--slogan-size) / (169 / 26));
   /* 20px */
  /* --h4-text-size: calc(var(--slogan-size) / (169 / 22.7)); */
  --h4-text-size: 1.25rem;

  /* 16px */
  /* --h5-text-size: calc(var(--slogan-size) / (169 / 18)); */
  --h5-text-size: 1rem ;
  --size-50-px: calc(var(--slogan-size) / (169 / 50));
  --size-100-px: calc(var(--slogan-size) / (169 / 100));
  --size-150-px: calc(var(--slogan-size) / (169 / 150));
}

body {
  background: var(--color-light);
  overflow-x: hidden;
}

html, body {
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
}

html::-webkit-scrollbar, body::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Edge */
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 400vh;
  min-width: 100vw;
}

#navbar {
  width: 100vw;
  position: fixed;
  background-color: #0005;
  color: white;
  z-index: 10;
  font-size: calc(var(--h2-text-size) * 0.7);
  font-weight: bold;
  height: calc(var(--h2-text-size) * (8 / 2.2));
}

#navbar .wrapper {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: calc(1440px*0.83);
  left: 50%;
  transform: translate(-50%);
  position: fixed;
  padding-top: 1vmin;
  padding-bottom: 1vmin;
  padding-left: 2vmin;
  padding-right: 2vmin;
  height: calc(var(--h2-text-size) * (8 / 2.2));
}


.take-action {
  padding: calc(var(--slogan-size) / (169 / 20)) calc(var(--slogan-size) / (169 / 34));
  border-radius: 34px;
  text-align: left;
  text-wrap: nowrap;
  width: min-content;
  font-size: var(--h3-text-size);
  color: var(--color-for-details);
  background-color: white;
  
}

#navbar .take-action {
  float: right;
  margin-right: 2vmin;
  color: white;
  font-size: calc(var(--h2-text-size) * 0.7);
  background-color: var(--color-for-details);
}

#App {
  width: 100vw;
}

#home {
  text-align: center;
  position: relative;
}

#home h1 {
  font-size: var(--slogan-size);
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  color: white;
  bottom: 0.9vmin;
  z-index: 5;
  letter-spacing: 7px;
}

#home .header {
  height: 30vmin;
  position: relative;
  background-color: var(--color-dark);
}

#home img {
  width: 100%;
  /* height: 100vh;
  object-fit: cover;
  object-position: center; */
  margin-top: -7vmin;
  position: relative;
}

#home .img_container span.text {
  color: white;
  width: 100%;
  font-size: calc(var(--h2-text-size) * 0.8);
  /* font-size: 2.5rem; */
  letter-spacing: -2px;
}

#home .text-over {
  text-align: right;
  transform: translateY(10%);
  left: 45%;
  position: absolute;
  z-index: 5;
  font-weight: bold; 
}


#home .text-over .cop-location {
  width: 180%;
  text-align: left;
  position: fixed;
  left: 20%;
  color: var(--color-for-details);
  font-size: var(--h1-text-size);
  background-color: var(--color-light);
  border-radius: 34px;
  padding-left: calc(var(--slogan-size) / (169 / 45));
  padding-top: calc(var(--slogan-size) / (169 / 20));
  padding-bottom: calc(var(--slogan-size) / (169 / 20));
}

#home .cop-location svg {
  height: var(--h1-text-size);
  margin-bottom: -0.5vmin;
}

#home .text-over span.text {
  display: block;
  position: relative;
  left: 20%;
  padding-top: 40%;
  text-align: center;
}

#home .text-over span.text u {
  font-weight: 700;
  color: var(--color-for-details)
}

#home .text-over .take-action {
  margin-top: 11%;
  margin-left: 0;
  text-align: center;
  left: 0;
  font-size: calc(var(--h2-text-size)*0.8);
}

/* ---- */

.margin-top-150 {
  margin-top: var(--size-150-px)
}

.margin-bottom-150 {
  margin-bottom: var(--size-150-px)
}

.padding-top-150 {
  padding-top: var(--size-150-px)
}

.padding-bottom-150 {
  padding-bottom: var(--size-150-px)
}

/* ---- */

#intro-texts, #why, #human-rights, #gallery-1, #env-destr, #oil-platform, #gw-genocide {
  display: flex;
  flex-direction: column;
  font-size: var(--h4-text-size);
  line-height: 150%;
  font-weight: 400;
}

.citation {
  line-height: normal;
}

.citation svg {
  height: var(--h1-text-size);
}

#human-rights .citation .cttn-text, #oil-platform .citation .cttn-text{
  font-size: calc(var(--h2-text-size) * 0.7)!important;
}

.text-with-pic img {
  object-fit: contain;
  object-position: top;
}

.text-with-pic.left {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.text-with-pic.right {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
}

.text-with-pic:not(:first-child)  {
  margin-top: var(--size-100-px);
}

.text-with-pic .part {
  width: 44%;
}

.text-with-pic.left .part:last-child {
  margin-left: 6%;
}

.text-with-pic.left .part:first-child {
  margin-right: 6%;
}

.text-with-pic.right .part:last-child {
  margin-right: 6%;
}

.text-with-pic.right .part:first-child {
  margin-left: 6%;
}

.text-with-pic .text.part {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

/* ---- */

.max-width-content {
  max-width: calc(1440px * 0.83);
  margin-left: auto;
  margin-right: auto;
}

/* ---- */

.text-blck {
  text-align: center;
  font-size: var(--h2-text-size);
  font-weight: bold;
}

.text-blck .take-action {
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--size-50-px);
  text-transform: none!important;
}

p.cttn-author {
  font-size: var(--h4-text-size);
  font-weight: normal!important;
  text-transform: none!important;
}

p {
  margin-top: var(--size-50-px);
  padding-top: unset;
}

p span, .text-blck .text span {
  color: var(--color-for-details)
}

/* ---- */
.second-text-blck {
  background: url('/public/images/bg-2.jpg') lightgray;
  display: flex;
  flex-direction: column-reverse;
  /* height: 550px; */
  height: 34.375rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.description{
  font-size: var(--h5-text-size);
  text-align: right;
  margin-top: 0;
  font-weight: normal;
  text-transform: none;
  color: black;
  font-weight: 300;
  font-style: italic;
}

.second-text-blck .description {
  transform: translateY(calc(var(--h5-text-size) + 10px));
}

.second-text-blck .text {
  color: white;
}

.text-blck .text {
  padding-top: var(--size-150-px);
}

/* ---- */

#why, #env-destr, #oil-platform {
  background-color: var(--color-dark);
  color: var(--color-semi-light);
}

#why b {
  color: white;
}

b.colored {
  color: var(--color-for-details);
}

.text-header {
  color: white;
  text-align: left;
  font-size: var(--h1-text-size);
  font-weight: bold;
  text-transform: uppercase;
}

#why .whyFooter .card {
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  margin-right: 10%;
}

#why .whyFooter svg {
  margin-left: auto;
  margin-right: auto;
  width: var(--slogan-size);
  height: var(--slogan-size);
}

#why .whyFooter span {
  margin-top: var(--size-50-px);
  text-wrap: nowrap;
  font-weight: bold;
  color: white;
  text-align: center;
}

#why .whyFooter {
  display: flex;
  justify-content: center;
  margin-top: var(--size-100-px);
}

#why .description, #env-destr .text-with-pic .description, #oil-platform .text-with-pic .description {
  color: var(--color-semi-light);
}

/* ---- */

#human-rights .text-header {
  color: black;
}

b.colored.red {
  color: red;
}

/* ---- */

#gallery-3 div[class^="ImageSlider_container"]{
  background-color: white;
}

/* ---- */

.env-destr-citation .floating {
  padding-bottom: var(--size-100-px);
}

.env-destr-citation .floating img {
  margin-top: -20%;
  position: relative;
  width: 100%;
}

.env-destr-citation .text-blck .text {
  padding-top: 0;
}

/* ---- */

#gw-genocide .text-header {
  color: black;
}

/* ---- */

@media (max-width: 450px) {
  #home h1 {
    font-size: calc(var(--slogan-size) - 2vmin);
  }
  #home .text-over {
    left: 30%;
  }

  #home .text-over span.text {
    left: 0;
    letter-spacing: -1px;
    padding-top: 30%;
    font-size: calc(var(--h2-text-size) * 1.3);
  }
}

@media (max-width: 1000px) {
  :root {
    --h5-text-size: calc(var(--slogan-size) / (169 / 29));
    --h4-text-size: var(--h2-text-size);
    --h3-text-size: var(--h2-text-size);
    #home .text-over span.text {
      letter-spacing: -1px;
    }
  } 

  .citation .cttn-author {
    font-size: calc(var(--h4-text-size) * 0.7)!important;
  }

  #human-rights .citation .cttn-text, #oil-platform .citation .cttn-text {
    font-size: calc(var(--h2-text-size) * 0.8)!important;
  }

  #home .text-over .take-action {
    visibility: hidden;
  }

  .text-with-pic {
    flex-direction: column!important;
  }

  .text-with-pic .part {
    margin-left: 0!important;
    margin-right: 0!important;
    width: 100%;
  }

  .text-with-pic .part:not(:first-child)  {
    margin-top: var(--size-100-px);
  }

  #why .whyFooter span {
    font-size: calc(var(--h4-text-size)* 0.8);
    text-wrap: unset;
  }

  #why .whyFooter .card {
    margin-left: 4%;
    margin-right: 4%;
  }
}

@media (max-width: 1250px) {
  #home .text-over .text {
    width: 80%;
  }
}

@media (max-width: 1440px) {
  .max-width-content {
    margin-left: 8.5%;
    margin-right: 8.5%;
  }
}


