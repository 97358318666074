.container {
    width: 100%;
    min-height: 100%;
    background-color: var(--color-dark);
    color: var(--color-light);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 5vmin;
}

.title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 3.75rem;
    letter-spacing: -2px;
    text-align: center;
}

.text {
    width: 58%;
    font-size: 1.25rem;
    font-weight: 400;
    text-align: center;
}

.button {
    margin-top: 4vmin;
    width: 30%;
    text-align: center;
}

.button button{
    width: 100%;
}

.demandsLink {
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
    text-align: center;
}

.demandLinkText {
    text-decoration: underline;
}

.demandArrow {
    margin-left: 5px;
}

.active svg {
    transform: rotate(-180deg);
}

.demandArrow svg {
    transition: transform 1s ease;
}

.demands {
    width: 60%;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 2em;
}

.demands li:not(:first-child) {
    margin-top: 2em;
}