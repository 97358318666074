.container {
    background-image: url('/public/images/how-help-footer.jpg');
    background-size: cover;
    /* height: 862px; */
    height: 53.875em;
    margin-top: var(--size-150-px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
}

.extendedContainer {
    background-image: url('/public/images/how-help-footer-ext.jpg');
    background-size: cover;
    /* height: 1729px; */
    /* height: 107.75em; */
    height: 108.0625em; 
    margin-top: var(--size-150-px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
}

.content {
    width: 100%;
    height: 83%;
}
