.container {
    display: flex;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container .index {
    height: 35px;
    width: 30px;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    vertical-align: middle;
    line-height: 35px;
}