.navBar {
    
}

.navLink {
    margin-left: 1vmin;
    margin-right: 1vmin;
    cursor: pointer;
    font-size: 1.5rem; /* 24px */
    font-weight: 700;
}

.middleLinks {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    
}

.home {
    float: left;
    cursor: pointer;
    font-size: 1.5rem; /* 24px */
    font-weight: 700;
}