.button {
    /* width: 195px; */
    /* height: 69px; */
    width: 8.125em;
    height: 2.875em;
    /* border-radius: 34px; */
    border-radius: 1.417em;
    /* padding: 20px 32px; */
    padding: 0.83em 1.33em;
    text-wrap: nowrap;
    font-size: 1.5rem; /* 24px */;
    font-weight: 700;
    border: none;
}

.primary {
    background-color: var(--color-for-details);
    color:var(--color-light);
}

.primary:hover {
    background-color: var(--color-hover-primary);
}

.secondary {
    background-color: var(--color-light);
    color: var(--color-for-details);
}