.homeFooter {
    background-image: url('/public/images/text-1-bg.jpg');
    background-size: cover;
    color: var(--color-light);
    /* height: 474px; */
    height: 7.9em;
    margin-top: var(--size-150-px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 5vmin;
    font-size: 3.75rem;
}

.footerText {
    font-weight: 700;
    letter-spacing: -2px;
    text-align: center;
}

.footerBtn {
    text-align: center;
}