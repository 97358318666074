.preGWGenocide {
    background-image: url('/public/images/third-text-blck.jpg');
    background-size: cover;
    color: var(--color-light);
    /* height: 528px; */
    height: 8.8em;
    /* margin-top: var(--size-150-px); */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 5vmin;
    font-size: 2.75rem;
}

.preGWGenocideText {
    font-weight: 700;
    letter-spacing: -2px;
    text-align: center;
}

.preGWGenocideBtn {
    text-align: center;
}