.howHelp {
  display: flex;
  flex-direction: column;
  font-size: var(--h4-text-size);
  line-height: 150%;
  font-weight: 400;
}

.textHeader {
    color: black;
    text-align: left;
    font-size: var(--h1-text-size);
    padding-top: var(--size-150-px);
    font-weight: 700;
    text-transform: uppercase;
}

.linkContainer {
    margin-top: var(--size-100-px);
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
    /* gap: 50px; */
    gap: 3.125rem
}

.linkItem {
    width: 75%;
    display: flex;
    flex-direction: row;
    /* gap: 30px; */
    gap: 1.875rem
}

.linkItemIcon {

}

.linkItemText {
    font-size: 1.25rem;
    font-weight: 400;
}

.helpLink {
    font-weight: 700;
    color: var(--color-for-details);
}